<script setup>
import { useRecentlyViewedStore } from "../stores";
const { $get } = useAxios();
const recentlyViewedStore = useRecentlyViewedStore();

const productIds = computed(() =>
  recentlyViewedStore.recentlyViewed.map((item) => item.productId).toString()
);

const { data: viewedProduct, pending: isLoading } = await useAsyncData(
  "get recently product",
  () => $get(`/api/v1/product?filter=public_id||$in||${productIds.value}`),
  { server: false }
);

const sortProducts = computed(() => {
  let data = [];
  if (viewedProduct.value && viewedProduct.value.length) {
    viewedProduct.value.forEach((item) => {
      let { createdAt } = recentlyViewedStore.recentlyViewed.find(
        (elem) => elem.productId === item.public_id
      );
      if (createdAt) {
        data.push({ viewedAt: createdAt, ...item });
      }
    });
    return data.sort(function (a, b) {
      return new Date(b.viewedAt) - new Date(a.viewedAt);
    });
  } else {
    return [];
  }
});
</script>

<template>
  <div v-if="!isLoading">
    <div v-if="sortProducts && sortProducts.length">
      <div
        class="container px-4 xl:px-0 font-lato font-semibold text-xl lg:text-4xl text-primary-900 pb-0 lg:pb-2"
      >
        Недавно смотрели
      </div>
      <ClientOnly>
        <p-slider-product :products="sortProducts"></p-slider-product>
      </ClientOnly>
    </div>
  </div>
  <div v-else class="container mb-4 px-4 xl:px-0">
    <p
      class="font-lato font-semibold pb-5 text-xl lg:text-4xl text-primary-900"
    >
      Недавно смотрели
    </p>
    <div
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
    >
      <p-card-sceleton class="" />
      <p-card-sceleton class="hidden sm:block" />
      <p-card-sceleton class="hidden lg:block" />
      <p-card-sceleton class="hidden xl:block" />
    </div>
  </div>
</template>
